<template>
    <div class="text-left newBodyWidth m-b-75">
        <searchLive></searchLive>
        <div class="top">找到“{{roomTitle}}” 相关内容共{{count}}个</div>
        <div class="tabs">
            <div class="tab" :class="tab==1?'select':''" @click="tab=1">相关直播 <span></span></div>
            <div class="tab" :class="tab==2?'select':''" @click="tab=2">相关预告 <span></span></div>
        </div>
        <div class="boxs box2s">
            <template v-if="tab==2">
                <template v-if="list0 && list0.length">
                    <div v-for="(v,i) of list0" class="box2" :key="i">
                        <div class="box22 pointer" @click="gotoLive(v)">
                            <div class="roomIcons"><img :src="$oucy.ossUrl+v.roomIcon" alt="" class="roomIcon">
                                <liveType :data="v" class="liveType"></liveType>
                            </div>
                            <div class="bottom">
                                <div class="df-jc-s-b">
                                    <div class="line-1">{{v.roomTitle}}</div>
                                    <div><img src="@/assets/images/live/fire.png" alt=""> {{v.liveRoomPBasic.pInteract||0}}</div>
                                </div>
                                <div class="df-jc-s-b df-ai-c m-t-10">
                                    <div class="df-ai-c"><img :src="$oucy.ossUrl+v.ltUserAuthBasic.detailAvatar" v-if="v.ltUserAuthBasic && v.ltUserAuthBasic.detailAvatar" alt="" class="head"><span class="detailNickname m-l-10">{{v.ltUserAuthBasic.detailNickname}}</span></div>
                                    <div class="pInteract">{{v.liveRoomPBasic.pInteract||0}}人正在观看</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="df-jc-c df-ai-c c-2 f14 text-center" style="height:50vh;width: 100%;" v-else><div><img src="@/assets/images/live/search_not.png" alt=""><div class="m-t-80">无任何搜索结果</div></div></div>
            </template>
            <template v-if="tab==1">
                <template v-if="list3 && list3.length">
                    <div v-for="(v,i) of list3" class="box2" :key="i">
                        <div class="box22 pointer" @click="gotoLive(v)">
                            <div class="roomIcons"><img :src="$oucy.ossUrl+v.roomIcon" alt="" class="roomIcon">
                                <liveType :data="v" class="liveType"></liveType>
                            </div>
                            <div class="bottom">
                                <div class="df-jc-s-b">
                                    <div class="line-1">{{v.roomTitle}}</div>
                                    <div><img src="@/assets/images/live/fire.png" alt=""> {{v.liveRoomPBasic.pInteract||0}}</div>
                                </div>
                                <div class="df-jc-s-b df-ai-c m-t-10">
                                    <div class="df-ai-c"><img :src="$oucy.ossUrl+v.ltUserAuthBasic.detailAvatar" v-if="v.ltUserAuthBasic && v.ltUserAuthBasic.detailAvatar" alt="" class="head"><span class="detailNickname m-l-10">{{v.ltUserAuthBasic.detailNickname}}</span></div>
                                    <div class="pInteract">{{v.liveRoomPBasic.pInteract||0}}人正在观看</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="df-jc-c df-ai-c c-2 f14 text-center" style="height:50vh;width: 100%;" v-else><div><img src="@/assets/images/live/search_not.png" alt=""><div class="m-t-80">无任何搜索结果</div></div></div>
            </template>
        </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { search_notice, liveroom, icon } from '@/service/index.js'
import emoji from "@/util/emoji.json"
export default {
    name: "listSearch",
    data() {
        return {
            list0: [],
            list3: [],
            date: {},
            tab: 1,
            dateList: [],
            roomTitle: null,
            roomCategory: null,
            count: 0,
        }
    },
    computed: {

    },
    watch: {
        $route: {
            handler(to, from) {
                this.roomTitle = to.query.roomTitle
                this.roomCategory = to.query.roomCategory != null && to.query.roomCategory != 'null' ? to.query.roomCategory*1 : null
                this.queryAllLiveRoom()
            },
            deep: true,
            immediate: true
        }
    },

    mounted() {

        // this.queryAllLiveRoom()
        // this.dateAdd()
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0)
    },
    methods: {
        queryAllLiveRoom() {
            this.count = 0
            // 直播状态0预告 3开播中 4已结束
            liveroom.queryAllLiveRoom({ start: 0, limit: 1000, roomAuthStatus: 1, roomTitle: this.roomTitle, roomCategory: this.roomCategory, roomStatus: 0 }).then(res => {
                this.list0 = res && res.content ? res.content : []
                this.count += res.totalElements
            })
            liveroom.queryAllLiveRoom({ start: 0, limit: 1000, roomAuthStatus: 1, roomTitle: this.roomTitle, roomCategory: this.roomCategory, roomStatus: 3 }).then(res => {
                this.list3 = res && res.content ? res.content : []
                this.count += res.totalElements
            })
        },

        dateAdd() {
            let date = new Date()
            let dateList = []
            for (var i = 0; i < 6; i++) {
                let d = date.DateAdd('d', i)
                dateList.push({
                    id: i,
                    d: d.format('d'),
                    date: d.format('yyyy-MM-dd'),
                })
            }
            this.date = dateList[0]
            this.dateList = dateList
        },
        gotoLive(v) {
            this.$oucy.go('/live?id=' + v.id)
        }
    }
}
</script>
<style scoped lang="less">
.top {
    margin-top: 30px;
    padding-left: 15px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 0px;
    color: #707070;
}

.tabs {
    display: flex;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 16px;
    color: #555C68;
    padding: 30px 0;

    .tab {
        cursor: pointer;
        position: relative;
        margin-left: 15px;
        margin-right: 65px;
        padding-bottom: 20px;

        span {
            position: absolute;
            left: 50%;
            bottom: 0px;
            transform: translateX(-50%);
            width: 24px;
            height: 6px;
            background: #2090FF;
            opacity: 1;
            border-radius: 20px;
            display: none;
        }

        &.select {
            font-weight: bold;

            span {
                display: inline-block
            }
        }
    }
}

.boxs {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
}

.box {
    margin: 10px;
    background: #fff;
    border-radius: 8px;

    .roomIcon {
        width: 100%;
    }
}

.boxx {
    width: 33.33%;

}

.title {
    font-size: 24px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #555C68;
    margin-top: 30px;
    margin-bottom: 20px;
}

.roomIcons {
    position: relative;
}

.roomIcon {
    border-radius: 10px;
}

.bottom {
    padding: 30px;
}

.head {
    width: 32px;
    height: 32px;
    border-radius: 32px;
}

.roomTitle {
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    line-height: 16px;
    color: #555C68;
}

.detailNickname {
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    line-height: 16px;
    color: #555C68;
}

.pInteract {
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    line-height: 16px;
    color: #AAAFB7;
}

.fl {
    flex: 3;
}

.fr {
    flex: 1;
    margin-left: 10px;
    margin-top: 10px;
}

.just {
    height: 707px;
    overflow: auto;
    color: #555C68;
    font-size: 16px;
    padding: 20px;
    box-sizing: border-box;
    background: #fff;

    .time {
        font-size: 14px;
        color: #868C94;
    }

    .roomIcon {
        width: 100%;
    }

    .justBox {
        margin-bottom: 20px;
    }
}

.box2s {
    min-height: 50vh;
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
}

.box2 {
    width: 25%;
}

.box22 {
    background: #fff;
    border-radius: 8px;
    margin: 10px;

    .roomIcon {
        width: 100%;
    }
}

.listBox {}

.liveType {
    position: absolute;
    bottom: 10px;
    right: 10px;
}
</style>